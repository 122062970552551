<template>
  <div
    ref="echarts"
    :class="className"
    :style="{ height: height, width: width }"
    auto-resize
    style="width:100%;margin:auto;"
  />
</template>
<script>
import echarts from "echarts";
require("echarts/theme/macarons");
import resize from "@/components/mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    seriesA: {
      type: String,
      default: "expected",
    },
    seriesB: {
      type: String,
      default: "actual",
    },
    prefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(val) {
      var data = [
        {
          name: `$${val.Standard.toFixed(0)}\nstandard`,
          itemStyle: {
            color: {
              type: "linear",
              colorStops: [{ offset: 0, color: "#6d7a86" }],
              global: false,
            },
          },
          label: {
            color: "white",
            lineHeight: 20,
          },
          children: [
            {
              name: "On-Peak",
              itemStyle: {
                color: {
                  type: "linear",
                  colorStops: [{ offset: 0, color: "#ffdc20" }],
                  global: false,
                },
              },
              label: {
                rotate: "tangential",
                color:
                  1 - val.usageProportionValue / 100 < 0.19
                    ? "black"
                    : "#0052cc",
              },
              value: 1 - val.usageProportionValue / 100,
              children: [
                {
                  name: `$${val.OnPeak.toFixed(0)}`,
                  value: 1 - val.usageProportionValue / 100,
                  itemStyle: {
                    color: {
                      type: "linear",
                      colorStops: [{ offset: 0, color: "#ffdc20" }],
                    },
                  },
                  label: {
                    color: "#0052cc",
                    fontSize: 20,
                  },
                },
              ],
            },
            {
              name: "Off-Peak",
              value: val.usageProportionValue / 100,
              itemStyle: {
                color: {
                  type: "linear",
                  colorStops: [{ offset: 0, color: "#0052cc" }],
                  global: false,
                },
              },
              label: {
                rotate: "tangential",
                color:
                  val.usageProportionValue / 100 < 0.19 ? "black" : "#ffdc20",
              },
              children: [
                {
                  name: `$${val.OffPeak.toFixed(0)}`,
                  value: val.usageProportionValue / 100,
                  itemStyle: {
                    color: {
                      type: "linear",
                      colorStops: [{ offset: 0, color: "#0052cc" }],
                      global: false,
                    },
                  },
                  label: {
                    color: "#ffdc20",
                    fontSize: 20,
                  },
                },
              ],
            },
          ],
        },
      ];
      var option = {
        series: {
          type: "sunburst",
          data: data,
          radius: [0, "100%"],
          nodeClick: false,
          label: {
            rotate: 0,
            fontSize: 14,
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          },
        },
      };
      this.chart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 400px;
}
</style>
